:root {
  --bhajan-details-background: #fff;
  --bhajan-title-text: #000;
  --lyrics-text: #333;
}

@media (min-width: 676px) {
  .go-back {
    top: 163px;
    left: 20px;
  }

  .font-minus {
    top: 163px;
    right: 47px;
  }

  .font-plus {
    top: 163px;
    right: 20px;
  }

  .share-bhajan {
    top: 227px;
    right: 20px;
  }

  .transliterate {
    top: 195px;
    right: 20px;
  }
}

@media (max-width: 675px) and (min-width: 385px) {
  .go-back {
    top: 209px;
    left: 20px;
  }

  .font-minus {
    top: 209px;
    right: 47px;
  }

  .font-plus {
    top: 209px;
    right: 20px;
  }

  .share-bhajan {
    top: 273px;
    right: 20px;
  }

  .transliterate {
    top: 241px;
    right: 20px;
  }
}

@media (max-width: 384px) and (min-width: 296px) {
  .go-back {
    top: 254px;
    left: 20px;
  }

  .font-minus {
    top: 254px;
    right: 47px;
  }

  .font-plus {
    top: 254px;
    right: 20px;
  }

  .share-bhajan {
    top: 318px;
    right: 20px;
  }

  .transliterate {
    top: 286px;
    right: 20px;
  }
}

@media (max-width: 295px) and (min-width: 283px) {
  .go-back {
    top: 301px;
    left: 20px;
  }

  .font-minus {
    top: 301px;
    right: 47px;
  }

  .font-plus {
    top: 301px;
    right: 20px;
  }

  .share-bhajan {
    top: 365px;
    right: 20px;
  }

  .transliterate {
    top: 333px;
    right: 20px;
  }
}

@media (max-width: 282px) {
  .go-back {
    top: 348px;
    left: 20px;
  }

  .font-minus {
    top: 348px;
    right: 47px;
  }

  .font-plus {
    top: 348px;
    right: 20px;
  }

  .share-bhajan {
    top: 412px;
    right: 20px;
  }

  .transliterate {
    top: 380px;
    right: 20px;
  }
}

.bhajan-details-container {
  font-family: 'Noto Serif Devanagari', serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: var(--bhajan-details-background);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.details-content {
  margin-top: 0;
  font-size: 1rem;
}

.bhajan-title {
  color: var(--bhajan-title-text);
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.lyrics-container {
  text-align: left;
  margin: 0 auto;
  max-width: fit-content;
  line-height: 1.6;

  color: var(--lyrics-text);
}

.index {
  font-family: 'Times New Roman', Times, serif;
}

.font-minus,
.font-plus,
.go-back,
.scroll-to-top,
.share-bhajan,
.transliterate {
  position: fixed;
  background-color: rgba(128, 128, 128, 0.2);
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 25px;
  height: 23px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.share-bhajan {
  width: 15px;
  height: 21px;
}

.transliterate {
  width: 15px;
  height: 20px;
}

.font-minus,
.font-plus,
.go-back,
.scroll-to-top,
.share-bhajan,
.transliterate svg {
  width: 15px;
  height: 20px;
  fill: #fff;
  transition: fill 0.3s ease;
}

.share-bhajan svg {
  transform: scale(0.9);
}

.transliterate svg {
  transform: scale(1);
}

.font-minus,
.font-plus,
.go-back,
.scroll-to-top,
.share-bhajan,
.transliterate:hover svg {
  fill: #000;
}

.font-minus,
.font-plus,
.go-back,
.share-bhajan,
.transliterate {
  display: flex;
}

.scroll-to-top {
  display: none;
  bottom: 20px;
  right: 20px;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
}

.ReactModal__Content {
  background-color: #F4F3EE;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 60%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1060;
  position: relative;
}

.ReactModal__Content h2 {
  font-family: 'Noto Serif Devanagari', serif;
  font-size: 24px;
  font-weight: lighter;
  margin-bottom: 10px;
  margin-top: 0;
  color: #555;
}

.ReactModal__Content button {
  background-color: #BCB8B1;
  color: #555;
  font-size: 16px;
  font-weight: lighter;
  padding: 10px 20px;
  margin: 2px 0;
  display: block;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ReactModal__Content button:hover {
  background-color: #A19B91;
}
:root {
  --primary-background: #f5f5f5;
  --app-text-color: #000;
  --app-background-color: #22333B;
  --header-text-color: #fff;
  --link-list-background: #F2F4F3;
  --table-border-color: #DEE3E0;
  --table-header-background: #fff;
  --link-background: #fff;
  --link-hover-background: #fff;
  --link-text-color: #000;
  --link-item-text-color: #555;
  --menu-button-background: #DEE3E0;
  --menu-button-text: #fff;
  --menu-button-hover-background: #C8D0CB;
  --icon-color: #22333B;
  --footer-background: #22333B;
  --footer-text-color: #fff;
}

body {
  margin: 0;
  font-family: 'Arial', 'Helvetica', sans-serif;
  background-color: var(--primary-background);
}

.App {
  text-align: center;
  margin: 10px;
}

@media (min-width: 676px) {
  .link-list {
    margin-top: 153px;
  }
}

@media (max-width: 675px) and (min-width: 385px) {
  .link-list {
    margin-top: 200px;
  }
}

@media (max-width: 384px) and (min-width: 296px) {
  .link-list {
    margin-top: 245px;
  }
}

@media (max-width: 295px) and (min-width: 283px) {
  .link-list {
    margin-top: 292px;
  }
}

@media (max-width: 282px) {
  .link-list {
    margin-top: 338px;
  }
}

@media (min-width: 320px) {
  .button-sort:first-child {
    margin-left: 14px;
  }
}

@media (max-width: 320px) {
  .button-sort:first-child {
    margin-left: 4px;
  }
}

.App-header {
  position: fixed;
  top: 0;
  left: 10px;
  right: 10px;
  width: auto;
  background-color: var(--app-background-color);
  color: var(--header-text-color);
  padding: 20px;
  border-radius: 4px;
  z-index: 1000;
  transition: background-color 0.8s ease-in-out;
}

h1 {
  margin: 0;
  font-size: 2.5rem;
}

h2 {
  font-family: 'Noto Serif Devanagari', serif;
}

.search-container {
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.input-container {
  position: relative;
}

input {
  position: relative;
  padding: 10px;
  width: calc(100% - 30px);
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 1px;
  margin-left: 0;
}

.link-list {
  background-color: var(--link-list-background);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  border: 1px solid var(--table-border-color);
  padding: 15px;
  text-align: left;
  background-color: var(--table-header-background);
}

td {
  border: 1px solid var(--table-border-color);
  padding: 7px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
}

th:first-child,
td:first-child {
  width: 5%;
}

.link-item {
  display: block;
  padding: 0px;
  text-decoration: none;
  color: var(--link-text-color);
  transition: background-color 0.3s ease;
}

.link-item:hover {
  background-color: var(--link-hover-background);
  border-radius: 8px;
}

.link-item h2 {
  margin: 0;
  font-size: 1.2rem;
  margin-left: 9px;
  text-align: left;
}

.link-item p {
  margin: 0;
  font-size: 0.9rem;
  color: var(--link-item-text-color);
  font-family: 'Times New Roman', Times, serif;
  margin-left: 9px;
  text-align: left;
}

.button-star,
.button-sort,
.button {
  padding: 10px 20px;
  background-color: var(--menu-button-background);
  color: var(--menu-button-text);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-left: 2px;
  margin-right: 1px;
}

.button-star:hover,
.button-sort:hover,
.button:hover {
  background-color: var(--menu-button-hover-background);
}

.button-star svg,
.button-sort svg,
.button svg {
  color: var(--icon-color);
}

.button-star,
.button-sort {
  border-radius: 20px;
  padding: 10px 10px;
}

.ReactModal__Content {
  background-color: #F4F3EE;
  padding: 15px;
  border-radius: 10px;
  width: 500px;
  max-width: 65%;
  max-height: 65vh;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  z-index: 1060;
  position: relative;
  display: flex;
  flex-direction: column;
}

.ReactModal__Content h2 {
  font-family: 'Noto Serif Devanagari', serif;
  font-size: 24px;
  font-weight: lighter;
  color: #555;
}

.ReactModal__Content .help-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: auto;
  height: auto;
  margin-bottom: 10px;
}

.ReactModal__Content .help-modal-header h2 {
  font-family: 'Noto Serif Devanagari', serif;
  font-size: 30px;
  font-weight: lighter;
  color: #555;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  flex-grow: 1;
  text-align: center;
}

.ReactModal__Content .help-modal-content {
  flex: 1;
  padding: 0 5px;
  overflow-y: auto;
}

.ReactModal__Content .help-modal-content p {
  text-align: justify;
  color: #555;
}

.ReactModal__Content .help-modal-content ul {
  padding-left: 15px;
  margin: 0;
}

.ReactModal__Content .help-modal-content li {
  text-align: justify;
  margin-bottom: 8px;
  color: #555;
}

.ReactModal__Content .help-modal-content h4 {
  font-family: 'Noto Serif Devanagari', serif;
  font-weight: bolder;
  color: #474747;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  flex-grow: 1;
  text-align: left;
}

.ReactModal__Content .close-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 28px;
  color: #555;
  cursor: pointer;
  width: auto;
  height: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin-left: auto;
}

.ReactModal__Content .close-button svg {
  transform: scale(0.8);
}

.ReactModal__Content .social-icons-container {
  padding: 5px 0 0 0;
}

.ReactModal__Content .social-icons {
  display: flex;
  justify-content: center;
  margin: 10px 0 0 0;
  padding: 0 0 0 0;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.ReactModal__Content .social-icons a {
  color: #555;
  font-size: 24px;
  text-decoration: none;
  transition: transform 0.25s ease;
}

.ReactModal__Content .social-icons a:hover {
  transform: scale(1.15);
  filter: brightness(0.9);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: #F4F3EE;
  border: 1px solid #ccc;
  padding: 0;
  margin: 0;
  width: 100px;
  list-style: none;
  z-index: 1000;
}

.dropdown-menu li {
  padding: 4px;
  cursor: pointer;
  font-family: 'Noto Serif Devanagari', serif;
  font-size: 12px;
  font-weight: lighter;
  border-bottom: 1px solid #ccc;
  color: #555;
}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

@media (min-width: 460px) {
  .dropdown-menu {
    width: 100px;
  }
}

@media (max-width: 460px) and (min-width: 390px) {
  .dropdown-menu {
    width: 80px;
  }
}

@media (max-width: 390px) and (min-width: 361px) {
  .dropdown-menu {
    width: 55px;
  }
}